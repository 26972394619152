var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4pX8sFFsI_ebYpfCjhZHU"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENV,
  release: `${process.env.NEXT_PUBLIC_ENV}@${
    process.env.NEXT_PUBLIC_SHA || ''
  }`,
  beforeSend(event, hint) {
    const originalException = hint.originalException
    if (
      typeof originalException === 'string' &&
      originalException.indexOf('this error can be safely ignored') >= 0
    ) {
      return null
    }
    return event
  },
  dsn: SENTRY_DSN,

  // Note: Please never set sample rate to be 1.0
  // (because there is a transaction limit per month and the limit is shared within orgs)
  sampleRate: 0.1,
  tracesSampler() {
    return false
  },
})
