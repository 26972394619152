import Router from 'next/router'
import NProgress from 'nprogress'
import * as React from 'react'

import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ToastProvider } from '@wartek-id/toast'

import '@wartek-id/design-tokens/dist/guru-desktop/css/tokens.css'
import '@/styles/global.css'

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('@/configs/mocks')
}

const App = ({ Component, pageProps, router }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: Infinity,
          },
        },
      })
  )

  return (
    <QueryClientProvider client={queryClient}>
      <HydrationBoundary state={pageProps.dehydratedState}>
        <ToastProvider autoDismiss placement="top-center">
          <Component {...pageProps} key={router.route} />
        </ToastProvider>
      </HydrationBoundary>
    </QueryClientProvider>
  )
}

export default App
